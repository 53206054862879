import { useMutation } from "@apollo/client";
import { useDisclosure } from "@mantine/hooks";
import Link from "next/link";

import { Button, Modal, Stack, Text } from "@/components/ui/elements";

import { displayedAddHomeScreenMutation } from "./_graphql";

export function AddToHomeScreen() {
  const [opened, { open, close }] = useDisclosure(true);
  const [displayedAddHomeScreen] = useMutation(displayedAddHomeScreenMutation);
  const handleClose = () => {
    displayedAddHomeScreen({
      variables: { input: {} },
      onCompleted: () => {
        close();
      },
    });
  };
  return (
    <Modal
      opened={opened}
      close={handleClose}
      title="ホーム画面へ追加方法のお知らせ"
    >
      <Stack gap={10}>
        <Text>ホーム画面に追加すると、より便利にご利用いただけます。</Text>
        <Text>
          以下の『追加方法を確認する』またはマイページの『ホーム画面への追加方法はこちら』よりご確認いただけます。
        </Text>
        <Stack>
          <Button
            component={Link}
            href="https://2021-07-it-support.notion.site/fc8c82d4bd8145d1b29e4e952e6e1cd8?pvs=4"
            target="_blank"
            color="teal"
            size="sm"
          >
            追加方法を確認する
          </Button>
          <Button onClick={handleClose} variant="outline" size="sm">
            閉じる
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
}
