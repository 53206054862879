"use client";
import { useEffect } from "react";

import { useLazyQuery } from "@apollo/client";
import { useOs } from "@mantine/hooks";
import { usePathname } from "next/navigation";

import { notificationQuery } from "./_graphql";
import { AddToHomeScreen } from "./AddToHomeScreen";
import { FiveMinWorkReminder } from "./FiveMinWorkReminder";

export function WorkerNotification() {
  const [getNotifications, { data, loading }] = useLazyQuery(notificationQuery);
  const pathname = usePathname();
  const os = useOs();
  const isDesktop = os === "windows" || os === "macos" || os === "linux";
  useEffect(() => {
    let isSubscribed = true;
    const fetchNotifications = async () => {
      try {
        // コンポーネントがアンマウントされていない場合のみ実行
        if (isSubscribed) {
          await getNotifications();
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };
    fetchNotifications();
    // クリーンアップ関数
    return () => {
      isSubscribed = false;
    };
  }, [pathname, getNotifications]);
  if (loading) {
    return null;
  }
  const viewer = data?.viewer;
  const addHomeScreenDisplayed = viewer ? viewer.addHomeScreenDisplayed : true;
  return (
    <>
      <FiveMinWorkReminder
        notificationId={viewer?.fiveMinWorkReminderDisplayedId}
      />
      {!isDesktop && !addHomeScreenDisplayed && <AddToHomeScreen />}
    </>
  );
}
